<template>
    <div class="order-list">
        <table class="order-table">
            <thead>
                <tr>
                    <th>商品信息</th>
                    <th>单价/数量</th>
                    <th>兑换积分</th>
                    <th>支付金额</th>
                    <th>收货人</th>
                    <th>处理时间</th>
                    <th>订单状态</th>
                    <th>操作</th>
                </tr>
                <tr style="height: 10px"></tr>
            </thead>
            <tbody v-if="!list.length">
                <tr style="background: #fff">
                    <td colspan="9">
                        <No />
                    </td>
                </tr>
            </tbody>
            <tbody v-for="(item, index) in list" :key="index">
                <tr>
                    <td colspan="9">
                        <div class="order-head">
                            <div class="left">
                                <span class="sn">订单编号：{{ item.order_no }}</span>
                            </div>
                            <div class="right">
                                <el-button v-show="item.status == 30 || item.status == 40" type="text" size="small"
                                    @click="logisticsQuery(item.id)">物流信息</el-button>
                                <el-divider v-if="item.status == 30 || item.status == 40" direction="vertical"></el-divider>
                                <el-button type="text" size="small" @click="orderInfo(item.id)">订单详情</el-button>
                                <!-- <el-button type="text" size="small" @click="setSystemMessage(item)">备注</el-button> -->
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <!-- 商品信息 -->
                    <td>
                        <div class="goods-info">
                            <div class="item">
                                <el-image :src="item.goods_cover_picture"></el-image>
                                <div class="goods-title">
                                    <p>{{ item.goods_name }}</p>
                                </div>
                                <!-- <el-tag type="danger" style="color: #fbbc05; border: 1px solid #fbbc05; background: rgba(251, 188, 5, 0.1)" v-show="goodsInfo.batch_id">
                  <div style="display: flex; align-items: center">
                    <img style="margin-right: 5px" src="@/assets/image/batch.png" alt="" />
                    批发
                  </div>
                </el-tag>
                <el-tag type="danger" v-show="goodsInfo.room_id > 0">
                  <i class="iconfont icon-shouji" style="font-size: 14px"></i>
                  直播
                </el-tag>
                <el-tag type="danger" style="color: #f56ca7; border: 1px solid #ffacd0; background: #fff2f8" v-show="item.goods_type == 2">
                  <div style="display: flex; align-items: center">
                    <img style="margin-right: 5px" src="@/assets/image/group-icon.png" alt="" />
                    拼团
                  </div>
                </el-tag>
                <el-tooltip v-show="goodsInfo.distribution_infos" placement="top">
                  <el-tag type="warning">
                    <div style="display: flex; align-items: center">
                      <img style="margin-right: 5px" src="@/assets/distribution-icon.png" alt="" />
                      分销
                    </div>
                  </el-tag>
                  <template slot="content">
                    <p v-for="(distributionInfo, y) in goodsInfo.distribution_infos" :key="y">
                      {{ distributionInfo.type == 1 ? '直接佣金' : distributionInfo == 2 ? '间接佣金' : '次间接佣金' }}：{{
                        distributionInfo.distribution_person && distributionInfo.distribution_person.nickname
                      }}（￥{{ distributionInfo.amount }}）
                    </p>
                  </template>
                </el-tooltip> -->
                                <!-- <el-tag type="success" v-show="goodsInfo.refund_handle_state == 30">已退款</el-tag>
                <el-tag type="success" v-show="goodsInfo.refund_handle_state == 0 && goodsInfo.refund_id > 0">退款中</el-tag> -->
                            </div>
                        </div>
                    </td>
                    <!-- 单价数量 -->
                    <td>
                        <div class="goods-price">
                            <p>
                                <span>￥{{ item.price / item.goods_sum }}</span>
                                <span>x{{ item.goods_sum }}</span>
                            </p>
                        </div>
                    </td>
                    <!-- 兑换积分 -->
                    <td>{{ item.integral_amount }}积分</td>
                    <!-- 支付金额 -->
                    <td>
                        <div class="freight_fee">
                            <p>￥{{ item.amount }}</p>
                            <p v-if="item.freight">（运费：{{ parseFloat(item.freight) }}元）</p>
                        </div>
                    </td>
                    <!-- 收货人 -->
                    <td class="consignee">
                        <div>{{ item.consignee_name }}</div>
                        <div>{{ item.consignee_phone }}</div>
                    </td>
                    <!-- 下单时间 -->
                    <td>
                        {{ getDateformat(item.create_time) }}
                    </td>
                    <!-- 订单状态 -->
                    <td>
                        <span :style="{
                            color: item.status == 20 ? '#ff8e8d' : item.status == 30 ? '#fdc176' : '#409EFF',
                        }">
                            <span>{{ item.status == -10 ? '已关闭' : item.status == 10 ? '待支付' : item.status == 20 ? '待发货' :
                                item.status == 30 ? '待收货' : '已完成' }}</span>
                        </span>
                    </td>
                    <!-- 操作 -->
                    <td>
                        <el-button v-if="item.status == 20" type="primary" size="small"
                            @click.stop="orderSend(item)">发货</el-button>
                        <el-button v-if="item.status == 30" type="primary" size="small"
                            @click.stop="confirmReceipt(item)">确认收货</el-button>
                        <el-button v-if="item.status == 10" type="text" size="small"
                            @click.stop="cancelOrder(item)">取消订单</el-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
        <!-- 点击发货弹窗 -->
        <el-dialog title="发货" :visible.sync="showOrderSend" width="600px">
            <el-form label-width="90px" v-if="currentSelectOrderInfo.id">
                <el-form-item label="收货人：">{{ currentSelectOrderInfo.consignee_name }}</el-form-item>
                <el-form-item label="收货地址：">
                    {{ currentSelectOrderInfo.consignee_address }}
                </el-form-item>
                <el-form-item label="快递公司：">
                    <el-select v-model="express_id" filterable placeholder="请搜索">
                        <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="快递单号：">
                    <el-input v-model="tracking_no"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showOrderSend = !1">取 消</el-button>
                <el-button type="primary" @click="confirmSend">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 显示物流信息弹框 -->
        <el-dialog title="物流信息" :visible.sync="showGistics" width="40%">
            <p style="font-size: 16px; font-weight: 500; margin-bottom: 15px">{{ logisticsInfo.company_name }}：{{
                logisticsInfo.nu }}</p>
            <el-timeline :reverse="reverse">
                <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
                    {{ item.context }}
                </el-timeline-item>
            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showGistics = !1">取 消</el-button>
                <el-button type="primary" @click="showGistics = !1">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="备注" :visible.sync="showMessage" width="35%">
            <el-form v-if="currentSelectOrderInfo.id" label-width="85px">
                <el-form-item label="订单编号：">{{ currentSelectOrderInfo.pay_sn }}</el-form-item>
                <el-form-item label="总价：">{{ currentSelectOrderInfo.amount }}</el-form-item>
                <el-form-item label="收货人：">{{ currentSelectOrderInfo.consignee_address }}</el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="currentSelectOrderInfo.system_message" type="textarea" :rows="8"
                        placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMessage = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmSetSystemMessage">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="订单改价" :visible.sync="showChangePrice" width="35%">
            <el-form v-if="currentSelectOrderInfo.id" class="order-change">
                <el-form-item label="订单编号：">{{ currentSelectOrderInfo.pay_sn }}</el-form-item>
                <!-- <el-form-item label="收货人：">{{ currentSelectOrderInfo.extend_order_extend.reciver_infos.name }}</el-form-item> -->
                <el-form-item label="订单状态：">{{ currentSelectOrderInfo.order_status }}</el-form-item>
                <el-table :data="currentSelectOrderInfo.extend_order_goods"
                    :header-cell-style="{ 'background-color': '#F8F9FA' }" center>
                    <el-table-column prop="date" label="商品信息" width="300">
                        <template slot-scope="scope">
                            <div class="change-goods-info">
                                <el-image :src="scope.row.goods_img"></el-image>
                                <div class="goods-title">
                                    <p>{{ scope.row.goods_title }}</p>
                                    <p v-if="scope.row.sku_list">规格：{{ scope.row.sku_list.title }}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_num" label="单价/数量">
                        <template slot-scope="scope">
                            <p>{{ scope.row.goods_price }}</p>
                            <p>x{{ scope.row.goods_num }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_pay_price" label="小计"></el-table-column>
                    <el-table-column prop="difference_price" label="改价后">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.difference_price"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <el-form-item label="运费价格：" class="freight-price">
                    <el-input v-model="revise_freight_fee"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showChangePrice = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmChangePrice">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="取消订单" :visible.sync="showCancelOrder" width="35%">
            <el-form v-if="currentSelectOrderInfo.id" label-width="85px">
                <el-form-item label="取消原因：">
                    <el-select v-model="cancel_reason_id" filterable placeholder="请选择取消原因">
                        <el-option v-for="item in cancelList" :key="item.id" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="取消说明：">
                    <el-input v-model="state_remark" type="textarea" :rows="8" placeholder="请输入取消说明"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showCancelOrder = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmCancelOrder">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
import { mapState } from 'vuex';
export default {
    props: {
        ruleForm: {
            type: Object,
        },
        status: {
            type: Number,
        },
    },
    data () {
        return {
            page: 1,
            rows: 10,
            total: 0,
            list: [],
            showCancelOrder: !1,
            showGistics: !1,
            showChangePrice: !1,
            showMessage: !1,
            currentSelectOrderInfo: {},
            logisticsInfo: [], //物流信息
            showOrderSend: !1,
            order_id: '', //订单id
            express_id: '', //物流公司id
            tracking_no: '', //发货订单号
            cancel_reason_id: '', //取消原因id
            cancelInfo: [], //取消信息
            state_remark: '', //取消说明
            revise_freight_fee: '', //修改运费的价格
        };
    },
    components: {
        Paging: Paging,
    },
    computed: {
        ...mapState({
            logisticsList: state => state.order.logisticsList, //物流公司列表
            cancelList: state => state.order.cancelList,
        }),
    },
    watch: {
        showOrderSend (val) {
            if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
        },
        showCancelOrder (val) {
            if (val && !this.cancelList.length) this.$store.dispatch('order/getCancelList', { type: 4 });
        },
    },
    created () {
        this.getList();
    },
    methods: {
        confirmSend () {
            if (!this.tracking_no)
                return this.$message({
                    message: '请填写快递单号',
                    type: 'warning',
                });
            this.$axios
                .post(this.$api.serviceProvider.integral.updateStatus, {
                    id: this.currentSelectOrderInfo.id,
                    status: 30,
                    express_id: this.express_id,
                    express_number: this.tracking_no,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: '发货成功',
                            type: 'success',
                        });
                        this.getList();
                        this.$parent.$parent.getOrderNum();
                        this.showOrderSend = !1;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        updateData (val, status) {
            if (status == 0) {
                this.rows = val;
            } else {
                this.page = val;
            }
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        orderInfo (id) {
            this.$router.push({
                path: '/extension/pointsMall/websitePointsOrderInfo',
                query: {
                    id: id,
                },
            });
        },
        //确认收货
        confirmReceipt (item) {
            // let orderInfo = this.list[index].extend_order_goods;
            // for (let i in orderInfo) {
            //   if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可确认收货！');
            // }
            this.$confirm(`是否确认强制收货（谨慎使用！）`, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
            }).then(() => {
                this.$axios.post(this.$api.serviceProvider.integral.updateStatus, { id: item.id, status: 40 }).then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: '订单收货成功',
                            type: 'success',
                        });
                        this.getList();
                        this.$parent.$parent.getOrderNum();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        //打印
        // print(row) {
        //   let routeData = this.$router.resolve({
        //     path: '/print',
        //     query: {
        //       id: row.id,
        //     },
        //   });
        //   window.open(routeData.href, '_blank');
        // },
        //取消订单
        cancelOrder (row) {
            this.currentSelectOrderInfo = row;
            this.state_remark = '';
            this.showCancelOrder = !0;
        },
        comfirmCancelOrder () {
            if (!this.cancel_reason_id)
                return this.$message({
                    message: '请选择取消原因',
                    type: 'warning',
                });
            if (!this.state_remark)
                return this.$message({
                    message: '请填写取消说明',
                    type: 'warning',
                });
            this.$axios
                .post(this.$api.serviceProvider.integral.updateStatus, {
                    id: this.currentSelectOrderInfo.id,
                    status: -10,
                    cancel_explain: this.state_remark,
                    cancel_reason_id: this.cancel_reason_id,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showCancelOrder = !1;
                        this.$message({
                            message: '订单已取消',
                            type: 'success',
                        });
                        this.getList();
                        this.$parent.$parent.getOrderNum();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //查看物流信息
        logisticsQuery (id) {
            this.$axios.post(this.$api.serviceProvider.integral.logisticsQuery, { id: id }).then(res => {
                if (res.code == 0) {
                    this.showGistics = !0;
                    res.result.info.company_name = res.result.company_name;
                    this.logisticsInfo = res.result.info;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //改价
        // changePrice(row) {
        //   this.currentSelectOrderInfo = row;
        //   if (this.currentSelectOrderInfo.revise_freight_fee > 0) {
        //     this.revise_freight_fee = this.currentSelectOrderInfo.revise_freight_fee;
        //   } else {
        //     this.revise_freight_fee = this.currentSelectOrderInfo.freight_fee;
        //   }
        //   this.showChangePrice = !0;
        // },
        // comfirmChangePrice() {
        //   let revise_goods = [];
        //   let extend_order_goods = this.currentSelectOrderInfo.extend_order_goods;
        //   for (let i in extend_order_goods) {
        //     if (!extend_order_goods[i].difference_price)
        //       return this.$message({
        //         message: '请填写修改价格',
        //         type: 'warning',
        //       });
        //     if (extend_order_goods[i].difference_price <= 0) return this.$message.warning('修改价格不能小于等于0');
        //     let data = {};
        //     data.id = extend_order_goods[i].id;
        //     data.goods_pay_price = Number(extend_order_goods[i].goods_pay_price);
        //     data.difference_price = extend_order_goods[i].difference_price;
        //     revise_goods.push(data);
        //   }
        //   this.$axios
        //     .post(this.$api.order.changePrice, {
        //       revise_goods: revise_goods,
        //       revise_freight_fee: Number(this.revise_freight_fee),
        //     })
        //     .then(res => {
        //       if (res.code == 0) {
        //         this.showChangePrice = !1;
        //         this.getList();
        //         this.$message({
        //           message: '修改成功',
        //           type: 'success',
        //         });
        //       } else {
        //         this.$message.error(res.msg);
        //       }
        //     });
        // },
        //设置备注信息
        setSystemMessage (row) {
            this.currentSelectOrderInfo = row;
            this.showMessage = !0;
        },
        comfirmSetSystemMessage () {
            this.$axios
                .post(this.$api.order.setSystemMessage, {
                    id: this.currentSelectOrderInfo.id,
                    system_message: this.currentSelectOrderInfo.system_message,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showMessage = !1;
                        this.$message({
                            message: '备注成功',
                            type: 'success',
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //发货
        orderSend (row) {
            // let orderInfo = row.extend_order_goods;
            // for (let i in orderInfo) {
            //   if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
            // }
            this.currentSelectOrderInfo = row;
            this.tracking_no = '';
            this.showOrderSend = !0;
        },
        getList () {
            let data = {
                page: this.page,
                rows: this.rows,
            };
            if (this.ruleForm.order_no) data.order_no = this.ruleForm.order_no;
            if (this.ruleForm.goods_name) data.goods_name = this.ruleForm.goods_name;
            if (this.ruleForm.consignee_name) data.consignee_name = this.ruleForm.consignee_name;
            if (this.ruleForm.consignee_phone) data.consignee_phone = this.ruleForm.consignee_phone;
            if (this.ruleForm.timeVal?.length) {
                data.start_time = this.ruleForm.timeVal[0].getTime() / 1000;
                data.end_time = this.ruleForm.timeVal[1].getTime() / 1000;
                if (data.start_time == data.end_time) data.end_time = data.end_time + 86400;
            }
            if (this.$parent.$parent.tab_type) data.status = this.$parent.$parent.tab_type;
            this.$axios.post(this.$api.serviceProvider.integral.list, data).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        // list[i].order_status = list[i].state == 0 ? '已关闭' : list[i].state == 10 ? '待支付' : list[i].state == 20 ? '待发货' : list[i].state == 30 ? '待收货' : '已完成';
                        // list[i].revise_freight_fee = Number(list[i].revise_freight_fee);
                        // list[i].revise_amount = Number(list[i].revise_amount);
                        // if (list[i].extend_order_extend.reciver_infos.combine_detail) {
                        //   list[i].extend_order_extend.reciver_infos.combine_detail = list[i].extend_order_extend.reciver_infos.combine_detail.replace(/\s*/g, '');
                        // }

                        if (list[i].from == 1) list[i].from_icon = wx;
                        if (list[i].from == 2) list[i].from_icon = ali;
                        if (list[i].from == 3) list[i].from_icon = fash;
                        if (list[i].from == 4) list[i].from_icon = douyin;
                        if (list[i].from == 5) list[i].from_icon = qq;
                        if (list[i].from == 6) list[i].from_icon = baidu;
                        // let extend_order_goods = list[i].extend_order_goods;
                        // for (let y in extend_order_goods) {
                        //   extend_order_goods[y].difference_price = extend_order_goods[y].goods_pay_price;
                        // }
                    }
                    this.list = list;
                    this.total = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.order-table {
    width: 100%;
    font-size: 14px;

    tbody {
        border: 1px solid #ebeef5;
    }

    thead tr th {
        padding: 15px;
        text-align: center;
    }

    thead tr {
        background: #f8f9fa;

        th:first-child {
            width: 280px;
        }

        th:last-child {
            width: 250px;
        }
    }

    tbody tr:first-child {
        border-bottom: 1px solid #ebeef5;

        background: #f8f9fa;

        td {
            padding: 5px 20px;
        }
    }

    tbody tr:last-child {
        td {
            text-align: center;
        }

        td:first-child {
            width: 280px;
        }

        td:last-child {
            width: 250px;
        }
    }

    .goods-info {
        width: 300px;
        flex: none;
        padding-left: 20px;

        .item {
            height: 70px;
            display: flex;
            align-items: center;

            .el-image {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                border-radius: 5px;
            }

            .goods-title {
                text-align: left;
                flex: 1;
                font-size: 13px;

                p:nth-child(1) {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

                p:nth-child(2) {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    margin-top: 5px;
                }
            }
        }
    }

    .order-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;

            .sn {
                margin-right: 70px;
            }

            div {
                display: flex;
                align-items: center;
            }

            .el-button {
                cursor: auto;
            }
        }

        .right {
            width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .goods-price p {
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70px;
        justify-content: center;

        span:nth-child(1) {
            margin-bottom: 5px;
        }
    }

    .freight_fee {
        p:nth-child(1) {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 19px;
                height: 20px;
                margin-left: 8px;
            }
        }

        i {
            font-size: 15px;
        }
    }

    .consignee p {
        &:nth-child(1) {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 16px;
                height: 16px;
                margin-left: 8px;
            }
        }
    }
}

.change-goods-info {
    display: flex;
    align-items: center;

    .el-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 5px;
    }
}

.freight-price {
    margin-top: 20px;

    .el-input {
        width: 100px;
    }
}</style>
